.footer {
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  padding: 0.2em;
  z-index: 0;
  text-align: left;
}

.footer p {
  margin: 6px 6px;
}
