.button {
  box-sizing: border-box;
  min-height: 70px;
  min-width: 20%;
  text-align: center;
  box-shadow: 0px 3px 3px 2px #EAEAEA;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.8em;
  font-weight: bolder;
  font-family: 'Cambria', serif;
  background-color: #000E0C;
  color: #AA7D39;
  border: 3px solid #7D5213;
  margin: 0.5em;
}

.button:hover,
.button:focus {
  background-color: #0F312D;
}

.button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 2px 1px #EAEAEA;
}

@media screen and (max-width: 640px) {
  .button {
    font-size: 1.4em;
    width: 80%
  }
}
