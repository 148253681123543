.box {
  position: fixed;
  box-sizing: border-box;
  text-align: center;
  border: 3px solid #AA7D39;
  color: black;
  background-color: white;
  padding: 1em;
  max-width: 500px;
  bottom: calc(0vh + 44px);
  right: calc(0vw + 44px);
  margin-left: 44px;
  z-index: 2;
}

.box p {
  font-size: 1.2em;
  margin: 0;
}

.box span {
  position: relative;
  cursor: pointer;
  top: -0.5em;
  left: calc(50%);
  color: lightgrey;
  margin: 0;
  padding: 0;
}

a {
  color: #7D5213;
}

a:active :visited{
  color: #330300;
}

.graphic {
  position: fixed;
  z-index: 2;
  box-sizing: border-box;
  height:30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  background-color: #AD6D00; /*Better Contrast with white i*/
  color: white;
  border: 1px solid white;
  top: calc(100vh - 1.7em); /*Roughly the size of the Footer*/
  left: calc(100vw - 50px);
}
