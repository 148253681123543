/* Colors */
/* 330300:Red 550400:Crimson AA7D39:Goldlight 7D5213:Goldmid 170E00:Golddark 000E0C:Tealdark */
.App {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #AA7D39;
  text-shadow: 0px 0px 2px #170E00;
  height: 100vh;
  width: 100%;
  font-family: 'Cambria', serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Game {
  display: block;
  position: relative;
  background-color: #330300;
  margin: 0;
  padding-top: 1.5em;
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;
  flex: column;
  justify-content: center;
  z-index: 1;
}

.Buttons {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  max-height: 20%;
}

@media screen and (max-width: 640px) {
  .Buttons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
