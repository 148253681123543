h1 {
  font-size: 2.4em;
}

.container {
  display: inline-block;
  margin: 0;
  padding: 0;
  min-height: 65%;
  max-height: 80%;
}

.fade {
  font-family: 'Dancing Script', cursive, serif;
  animation: fadeIn linear 1.3s;
  -webkit-animation: fadeIn linear 1.3s;
  -moz-animation: fadeIn linear 1.3s;
  -o-animation: fadeIn linear 1.3s;
  -ms-animation: fadeIn linear 1.3s;
}

.title {
  font-size: 10em;
}

@media screen and (max-width: 1240px) {
  .title {
    font-size: 6em;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 2em;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 1.6em;
  }
}

@keyframes fadeIn {
  0% {opacity:0; font-size: 3.5em}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0; font-size: 3.5em}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0; font-size: 3.5em}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0; font-size: 3.5em}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0; font-size: 3.5em}
  100% {opacity:1;}
}
